<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="title">订单信息</h5>
        </div>
        <ul class="listBox">
          <li>
            <span>创建时间</span>
            <i class="hidden1">{{ detail.createTime }}</i>
          </li>
          <li>
            <span>银行流水号</span>
            <i class="hidden1">{{ detail.bankTrxNo }}</i>
          </li>
          <li>
            <span>商户名称</span>
            <i class="hidden1">{{ detail.mchName }}</i>
          </li>
          <li>
            <span>商户编号</span>
            <i class="hidden1">{{ detail.mchId }}</i>
          </li>
          <li>
            <span>电子账簿名称</span>
            <i class="hidden1">{{ detail.accountName }}</i>
          </li>
          <li>
            <span>收款电子账簿编号</span>
            <i class="hidden1">{{ detail.accountNo }}</i>
          </li>
          <li>
            <span>付款方账户名称</span>
            <i class="hidden1">{{ detail.payBankAccountName }}</i>
          </li>
          <li>
            <span>付款方账号</span>
            <i class="hidden1">{{ detail.payBankAccountNo }}</i>
          </li>
          <li>
            <span>付款方银行名称</span>
            <i class="hidden1">{{ detail.payBankName }}</i>
          </li>
          <li>
            <span>付款方联行号</span>
            <i class="hidden1">{{ detail.payBankBrnNo }}</i>
          </li>
          <li>
            <span>入账金额（元）</span>
            <i class="hidden1">{{ (detail.amount / 100) | formatMoney }}</i>
          </li>
          <li>
            <span>手续费（元）</span>
            <i class="hidden1">{{ (detail.hjFee / 100) | formatMoney }}</i>
          </li>
          <li>
            <span>到账金额（元）</span>
            <i class="hidden1">{{
              ((detail.amount - detail.hjFee) / 100) | formatMoney
            }}</i>
          </li>
          <li>
            <span>入账状态</span>
            <i class="hidden1">{{ outStatusData[detail.outStatus] }}</i>
          </li>
          <li>
            <span>交易附言</span>
            <i class="hidden1">{{ detail.remark }}</i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getEntryInquiryrDetail } from "@/api/basic/balance.js";
export default {
  data() {
    return {
      outStatusData: {
        0: "银行处理中",
        1: "已到账",
        2: "到账失败",
      },
      detail: "",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    // 获取详情
    getDetail() {
      getEntryInquiryrDetail(this.$route.query.id).then((res) => {
        if (res) {
          this.detail = res.resultData.mchTradeIncomeOrder;
        }
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
</style>
